import { developerList, developerDel } from "@lib/index";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tableData: [],
      pageSize: 20,
      currentPage: 1,
      totalSize: 0,
      keyword: "",
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    async getData() {
      try {
        const { status, result } = await developerList({
          keyWords: this.keyword,
          developerId: this.userInfo?.id,
          pageNumber: this.currentPage,
          pageSize: this.pageSize,
        });
        if (status === "success") {
          this.tableData = result?.list || [];
          this.totalSize = result?.totalSize || 0;
        }
      } catch (e) {
        console.error(e);
      }
    },
    onSizeChange(e) {
      this.pageSize = e;
      this.getData();
    },
    onCurrentChange(e) {
      this.currentPage = e;
      this.getData();
    },
    onBind() {
      this.$router.push({ name: "accountBind" });
    },
    onExport() { },
    onSearch() { },
    onReset() {
      this.keyword = "";
      this.getData();
    },
    onDelete(e) {
      this.$confirm("确认删除？")
        .then(async () => {
          try {
            const { status, result } = await developerDel({ id: e.id });
            if (status === "success") {
              this.$message.success("删除成功");
              this.getData();
            } else {
              this.$message.error(result?.error_msg);
            }
          } catch (error) {
            console.error(error);
          }
        })
        .catch(() => { });
    },
    onView(e) {
      this.$router.push({
        name: "accountDetail",
        params: { ...e },
      });
    },
  },
};
